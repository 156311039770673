import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './components/landing/landing';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Terms from './components/terms/terms'; 
import Listing from './components/listing/listing';
import DetailsPage from './components/details/details';
import AdvertisePage from './components/advertise/advertise';
import ContactPage from './components/contact/contact';
import AboutPage from './components/about/about';

function App() {
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route path='/' element={ <Landing/> } />
        <Route path='/terms' element={ <Terms/> } />
        <Route path='/lists' element={ <Listing/> } />
        <Route path='/details/:id' element={ <DetailsPage/> } />
        <Route path='/advertise' element={ <AdvertisePage/> } />
        <Route path='/contact' element={ <ContactPage/> } />
        <Route path='/about' element={ <AboutPage/> } />
      </Routes>
  
    <Footer />
  </Router>
  );
}

export default App;
