import React from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";

// Map container style
const mapContainerStyle = {
  width: "50%",
  margin: "20px"
};

const MyMap = ({center, locations}) => {
  console.log(locations)
  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const [renderMarkers, setRenderMarkers] = React.useState(false);

  // Load the Google Maps API
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA52VJEnX8ec4zOmEDR-MpdxeGPzKs7s-E", // Replace with your API key
  });

  if (!isLoaded) return <div>Loading...</div>; // Display a loading message while the API loads

  // Render markers after 1 sec
  setTimeout(() => {
    setRenderMarkers(true);
  }, 1000);

  const calculateBestZoom = (map, markers) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(marker => {
      bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng));
    });
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10} 
    onLoad={(map) => {
      calculateBestZoom(map, locations);
    }}

    >
      {/* Render markers */}
      {renderMarkers && locations.map((location) => (
        <Marker
          key={location.id}
          position={location.position}
          onClick={() => setSelectedMarker(location)}
          icon={{
            url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Custom icon URL
            scaledSize: new window.google.maps.Size(40, 40), // Size of the icon
          }}

        />
      ))}

      {/* InfoWindow for displaying marker details */}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.position}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h3>{selectedMarker.name}</h3>
            <p>{selectedMarker.details}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default MyMap;
